<template>
  <v-container id="logout" fluid tag="section">
    <v-card class="mx-auto">
      <v-list-item two-line>
        <v-list-item-content>
          <div class="overline mb-4">
            {{ $t('user.confirm-logout') }}
          </div>
          <v-list-item-title class="headline mb-1">
            {{ $t('user.current-session') }}
          </v-list-item-title>
          <p class="text--secondary">
            {{ $t('user.login') }}: {{ session.login }}
          </p>
          <p class="text--secondary">
            {{ $t('user.logged-in') }}: {{ session.loggedInSince }}
          </p>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn class="accent" @click="logout">
          {{ $t('user.logout') }}
        </v-btn>
      </v-card-actions>

      <v-list-item v-if="session.user" two-line>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1">
            {{ $t('user.permissions') }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-for="(p, i) in session.user.permissions"
            :key="i"
          >
            {{ p }}
          </v-list-item-subtitle>

          <v-list-item-title class="headline mb-1">
            {{ $t('user.groups') }}
          </v-list-item-title>
          <v-list-item-subtitle v-for="(p, i) in session.user.groups" :key="i">
            {{ p }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    session() {
      return this.$store.state.session;
    }
  },
  mounted() {},
  methods: {
    logout() {
      this.$store.commit('LOGOUT');
      this.$router.push('Login').catch(() => {});
    }
  }
};
</script>
